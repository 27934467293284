@value secondary from './../colors.css';

.header {
  margin: 0;
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  background: white;
  overflow: visible;
}

.header img {
  height: 52px;
  object-fit: contain;
}

.nav__links {
  list-style: none;
}

.nav__links li {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 5px;
}

.nav__links li a {
  text-decoration: none;
  color: #4f4f4f;
}

.nav__links li:hover {
  background: rgba(79, 79, 79, 0.1);
}

.nav__links li a.active {
  color: secondary;
}
