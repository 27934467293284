@value primary, secondary, accent from './../colors.css';

.header {
  width: 100%;
  padding: 40px 0px;
  background-color: primary;
  display: grid;
  place-items: center;
  color: #fff;
}
