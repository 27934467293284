@value accent from './../colors.css';

.position_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.business_description {
  /* font-size: 1.2em; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1em;
  width: 550px;
}

.business_logo {
  max-width: 620px;
  height: 413px;
  object-fit: cover;
  margin-left: 30px;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.buttons a {
  margin-right: 1em;
}

.buttons a:first-child {
  background-color: accent;
}
