@value accent from './../colors.css';

.cards_container {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 22.27rem);
  grid-gap: 30px;
}

.link {
  color: accent;
  text-decoration: none;
}

.advices_table {
  margin-top: 30px;
  border-collapse: collapse;
}

.advices_table th {
  text-align: left;
}

.advices_table thead th {
  border-bottom: 2px solid accent;
}

.advices_table th,
.advices_table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid accent;
}
