@value primary, secondary, accent from './../colors.css';

.content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  width: 270px;
  height: 270px;
  border-radius: 50%;
  background-color: secondary;
  display: grid;
  place-items: center;
}

.avatar img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
}

.description {
  margin-left: 4.22rem;
  width: 36.66666666666rem;
}

.description > p {
  font-weight: 300;
}

.section {
  margin-top: 2.22rem;
  display: flex;
}

.section__content {
  width: 34.27rem;
}

.biography_picture {
  height: 39.11rem;
  width: 26.11rem;
  object-fit: cover;
}

.biography {
  align-items: center;
  justify-content: space-between;
}

.biography p {
  margin: 32px 0;
}

.courses {
  margin-left: 6.94rem;
}

.subsection {
  width: 27.77rem;
}

.contact {
  background-color: primary;
  display: flex;
  flex-direction: column;
}

.contact {
  color: #fff;
  padding: 1.66rem;
}

.contact img {
  width: 39.55rem;
  height: 26.33rem;
}

.contact > div {
  margin-top: 2.22rem;
  display: flex;
}

.contact label {
  font-weight: 500;
  margin-bottom: 0.55rem;
  display: inline-block;
}

.contact form {
  margin-left: 1.66rem;
  width: 39.55rem;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  width: 100%;
  border-radius: 0.55rem;
  border: none;
  padding: 0.77rem 0.55rem;
  font-size: 1.11rem;
  color: #000;
  box-sizing: border-box;
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="tel"]::placeholder,
textarea::placeholder {
  color: accent;
}

textarea {
  resize: none;
  height: 8.72rem;
}

.form_row {
  display: flex;
  justify-content: space-between;
}

.form_row > div {
  width: 18.94rem;
}

.form_control {
  margin-bottom: 1.11rem;
}

.recognitions img {
  min-width: 34.27rem;
  height: 22.83rem;
}

.recognitions > div {
  margin-top: 2.22rem;
  display: flex;
  align-items: center;
}

.recognitions ul {
  padding-left: 1.66rem;
  margin-right: 60px;
}

.recognitions ul p {
  margin-top: 0px;
}

.academic_profiles {
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.academic_profiles > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.academic_profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px;
}

.academic_profile > h3 {
  font-size: 36px;
  font-family: "Volkhov", serif;
}
