@value secondary from './../colors.css';

.btn {
  color: #fff;
  background-color: secondary;
  border-radius: 5px;
  border: 2px solid transparent;
  padding: 0.55rem 1.33rem;
  font-size: 1.11rem;
  cursor: pointer;
}
