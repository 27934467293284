@value accent from './../colors.css';

.footer {
  clear: both;
  position: relative;
  height: 74px;
  margin-top: -74px;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.15);
  background: white;

  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer img {
  height: 52px;
  object-fit: contain;
}

.social_links {
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 32px;
}

.social_links a {
  text-decoration: none;
  color: accent;
}

.social_links svg {
  margin-left: 10px;
}
