.image_card {
  width: 22.27rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  /* border: 2px solid #ccc; */
  box-sizing: border-box;
}

.image_card img {
  /* border: 2px solid transparent; */
  box-sizing: border-box;
  width: 100%;
  height: 14.83rem;
  border-radius: 5px 5px 0 0;
  object-fit: cover;
}

.body {
  padding: 0.55rem;
}

.body ul {
  margin: 0px;
}

.body span {
  display: block;
  padding: 0.55rem 0px;
}
