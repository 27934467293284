@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&family=Volkhov&display=swap");

* {
  outline: none;
}

html {
  font-size: 18px;
}

body {
  min-height: 100%;
}

body,
textarea {
  margin: 0;
  font-family: "Rubik", sans-serif;
}

h1,
h2,
h3,
h4 {
  margin: 10px 0;
  font-weight: 400;
}

h1,
h2 {
  font-family: "Volkhov", serif;
}

h1 {
  font-size: 3.55rem;
}

h2 {
  font-size: 2.66rem;
}

li::marker {
  color: #5fc28c;
}

b {
  font-weight: 500;
}

ul {
  margin: 0px;
}

.cards_container {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 22.27rem);
  grid-gap: 30px;
}

.cards_grid > div {
  margin-bottom: 40px;
}
