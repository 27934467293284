@value secondary from './../colors.css';

a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;

  text-decoration: none;
}

.button {
  color: #fff;
  background-color: secondary;
  border-radius: 5px;
  border: 2px solid transparent;
  padding: 5px 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.button svg {
  margin-left: 5px;
  font-size: 20px;
}
