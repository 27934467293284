.page_picture {
  min-width: 494px;
  height: 329px;
  object-fit: cover;
  /* border: 2px solid red; */
}

.page_description {
  align-items: center;
  margin: 100px 0 40px 0;
}

.page_description > div {
  /* width: 647px; */
  padding-left: 60px;
}

.page_description p {
  margin: 40px 0 20px 0;
}

.section {
  margin-top: 2.22rem;
  display: flex;
}
