@value accent from './../colors.css';

.card {
  /* width: 22.27rem; */
  border: 2px solid accent;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.body {
  padding: 20px;
}

.body ul {
  margin: 10px;
}

.body span {
  display: block;
  /* margin-bottom: 10px; */
}

.card hr {
  margin: 20px -21px;
  border: 0;
  border-top: 2px solid accent;
}
